<template>
  <div class="infomation_layout">
    <div class="head_lemontree">
      <h2 class="tit_info"><span v-html="stepData.title.front"></span><span class="tc_primary" v-html="stepData.title.primary"></span><span v-html="stepData.title.end"></span></h2>
      <p class="desc_section" v-html="stepData.desc"></p>
    </div>
    <div class="body_lemontree">
      <Button
        v-if="stepData.button"
        class="btn_body"
        :btnStyle="stepData.button.btnStyle"
        :btnSize="stepData.button.btnSize"
        :text="stepData.button.text"
        @onClickBtn="goUrl(stepData.button.routerUrl)" />
      <div
        :style="stepData.img"
        class="area_img"></div>
    </div>
    <div 
      v-if="stepData.tip"
      class="area_tip">
      <div class="box_tip">
        <div class="area_left">
          <div class="icon_tip">TIP</div>
        </div>
        <div class="area_overflow">
          <p class="desc_tip" v-html="stepData.tip"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/common/button/Button';

export default {
  name: 'InfomationLayout',
  props:{
    stepData: Object,
    index: Number
  },
  components:{
    Button,
  },
  data(){
    return{
      totalPage: 6
    }
  },
  methods:{
    goUrl(routerUrl){
      window.open(routerUrl, '_blank');
    }
  }
}
</script>

<style scoped>

.infomation_layout{display:flex;height:100%;flex-direction:column;background:#fff}

.head_lemontree,
.body_lemontree{padding:0 20px}
.head_lemontree{flex:0 1}
.body_lemontree{flex:1 1;position:relative;margin-top:40px}
.tit_info{font-weight:700;font-size:24px;line-height:30px;color:#111}
.desc_section{margin-top:24px;font-weight:500;font-size:16px;line-height:24px;color:#333;word-wrap:break-word;word-break:keep-all}
.btn_body{position:relative;z-index:100;display:inline-block;width:auto;margin-top:-16px;vertical-align:top}

/* .area_img{max-height:372px;background:url(/assets/images/card_info/card_screen_01.png) no-repeat center 0} */
/* .area_img{max-height:228px;background:url(/assets/images/card_info/card_screen_02.png) no-repeat center 0} */
.area_img{position:absolute;left:20px;right:20px;height:100%}

.area_tip{flex:0 1;position:relative;z-index:100;margin:-20px 20px 0;padding:20px 0 24px;background-image:linear-gradient(transparent 0%, #fff 24%)}
.box_tip{overflow:hidden;border-radius:8px;background-color:#F7F6FA;padding:14px}
.box_tip .icon_tip{width:40px;height:40px;margin:1px 12px 1px 0;padding:12px 0;border-radius:100%;font-weight:600;font-size:12px;line-height:16px;background-color:#D8CBF4;text-align:center;box-sizing:border-box;color:#111}
.box_tip .desc_tip{font-weight:500;font-size:14px;line-height:22px;color:#333}
</style>