<template>
  <div class="progress_step">
    <div class="inner_progress_step" :style="cssProps">
      <div
        v-for="index in total"
        :key="`step${index}`"
        :class="[
          'item_step',
          { 'passed': index < step },
          { 'active': index === step }
        ]">
        <div class="bar_active"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressStep',
  props:{
    total: Number,
    step: Number,
    speed: Number,
  },
  computed:{
    cssProps(){
      return {
        '--animation':`width ${this.speed/1000}s ease-out`,
        '--duration':`${this.speed/1000}s`
      }
    }
  }
}
</script>

<style scoped>
.inner_progress_step{display:flex;padding:32px 0}
.item_step{flex: 1 0;overflow:hidden;height:4px;border-radius:10px;background-color:#E7E5E5}
.item_step .bar_active{width:0;height:4px;background-color:#8F69DD}
.item_step.active .bar_active{width:100%;
  animation-name: progressFill;
  animation-duration:var(--duration);
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.item_step.passed .bar_active,
.item_step.active .bar_active{width:100%}
.item_step + .item_step{margin-left:8px}

@keyframes progressFill {
  0%{
    width:0;
  }
  100%{
    width:100%
  }
}
</style>